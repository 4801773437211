import '../App.css';
import axios from 'axios';
import React, {Component} from 'react';
import { Spinner } from 'react-bootstrap';

const getRSVPURL = "https://o3afgjrrg8.execute-api.us-east-1.amazonaws.com/Prod/get-rsvp/"

class MultipleResultsForm extends Component {

  state = {
    disable_button: false
  }

  back = (event) =>  {
    event.preventDefault();
    this.props.setStep('search_form');
  }

  handleSubmit = (item, event) => {
    event.preventDefault();
    this.setState({ disable_button: true });

    console.log('User selected an RSVP.')

    axios.get(getRSVPURL, {
      params: {
        first_name: item[0].first_name,
        last_name: item[0].last_name,
        invite_id: item[0].invite_id,
      }
    })
    .then(get_response => {
      const search_results = get_response.data.result.Items
      this.props.setsearchResults(search_results);
      this.props.setStep('rsvp_form');
    })
    .catch(error => {
      this.setState({searchResults: error.message});
      console.error("There was an error.", error);
    })

    this.setState({ disable_button: false });
  }

  render() {
    const multipleSearchResults = this.props.multipleSearchResults;
    const { disable_button } = this.state;

    return (
      <div className="App">
        <header className="App-header">
          <h1>RSVP</h1>
        </header>
        <div className="App-body">
          <React.Fragment>
            <div className='form-center-text'>
              <h3>Please select your party from the list below</h3>
            </div>
            { Object.keys(multipleSearchResults).map((item, i) => (
              <div key={i} className="multi-result-box" onClick={ (event) => this.handleSubmit(this.props.multipleSearchResults[item], event) }>
                <div className='row'>
                  {multipleSearchResults[item].map((rsvp, ind) =>
                    <div key={ind} className='column'>
                      {rsvp.first_name} {rsvp.last_name}
                      <input type="text" name="firstName" value={rsvp.first_name} type="hidden"/>
                      <input type="text" name="lastName" value={rsvp.last_name} type="hidden"/>
                      <input type="text" name="invite_id" value={rsvp.invite_id} type="hidden"/>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </React.Fragment>
          <div className="footer-controls">
            <div className="prev-button">
              <button type="button" class="btn btn-light" type="submit" onClick={this.back} disabled={this.state.disable_button}>
                {this.state.disable_button && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                )}
                {!this.state.disable_button && <span>Back</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultipleResultsForm;
