import './App.css';
import React, {Component} from 'react';
import MainForm from './components/MainForm'

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
    render() {
        return <React.Fragment>
            <MainForm />
        </React.Fragment>
    }
}

export default App;
