import '../App.css';
import axios from 'axios';
import React, {Component} from 'react';
import { Button, Spinner } from 'react-bootstrap';

const putRSVPURL = "https://o3afgjrrg8.execute-api.us-east-1.amazonaws.com/Prod/update-rsvp/"

class RsvpForm extends Component {

  state = {
    rsvp_responses: [],
    disable_button: false
  }

  back = (event) =>  {
    event.preventDefault();
    this.props.setStep('search_form');
  }

  handleBooleanChange = (event, index) => {
    event.preventDefault();
    this.props.updateNameSearchResults(event, index);
  }

  handleNameChange = (event, index) => {
    event.preventDefault();
    this.props.updateNameSearchResults(event, index);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ disable_button: true });

    const rsvp_responses = {
      rsvps: this.props.searchResults
    };
    axios.put(putRSVPURL,
      rsvp_responses
    )
    .then(response => {
      console.log('Successfully posted to RSVP Update.')
      this.props.setStep('success_form');
    })
    .catch(error => {
      this.setState({searchResults: error.message});
      console.error("There was an error.", error);
      this.setState({ disable_button: false });
    })
  }

  render() {

    return (
      <div className="App">
        <header className="App-header">
          <h1>RSVP</h1>
          <div className='form-center-text'>
            <h3>Kindly reply for each guest in your party</h3>
          </div>
        </header>
        <div className="App-body">
          <div className='form-center-text'>
            For guests staying at the Hyatt House, please let us know below if you plan on taking the shuttle to the venue.
          </div>
          <form className='form-box' onSubmit={this.handleSubmit}>
            { this.props.searchResults.map((rsvp, index) => {
              return (
                <div className='rsvp-box'>
                  <div className='row'>
                    <div className='column'>
                      <label>
                        First name
                        <input type="text" name="firstName" id="first_name" placeholder="First Name" class="rounded" required defaultValue={rsvp.first_name} onChange={event => this.handleNameChange(event, index)}/>
                      </label>
                    </div>
                    <div className='column'>
                      <label>
                        Last name
                        <input type="text" name="lastName" id="last_name" placeholder="Last Name" class="rounded" required defaultValue={rsvp.last_name} onChange={event => this.handleNameChange(event, index)}/>
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <input name="invite_id" value={rsvp.invite_id} type="hidden"/>
                    <input name="invite_group" value={rsvp.invite_group} type="hidden"/>
                    <div className='column'>
                      <label>
                        Response
                        <select name="attending" id="attending" className="dropdown" key={index} defaultValue={rsvp.attending} onChange={event => this.handleBooleanChange(event, index)}>
                          <option value="true">Accepts with pleasure</option>
                          <option value="false">Declines with regret</option>
                        </select>
                      </label>
                    </div>
                    <div className='column'>
                      <label>
                        Shuttle
                        <select name="shuttle" id="shuttle" className="dropdown" key={index} defaultValue={rsvp.shuttle} onChange={event => this.handleBooleanChange(event, index)}>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="footer-controls">
              <div className="prev-button">
                <button type="button" class="btn btn-light" type="submit" onClick={this.back} disabled={this.state.disable_button}>
                  {this.state.disable_button && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                  )}
                  {!this.state.disable_button && <span>Back</span>}
                </button>
              </div>
              <div className="next-button">
                <button type="button" class="btn btn-light" type="submit" disabled={this.state.disable_button}>
                  {this.state.disable_button && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                  )}
                  {!this.state.disable_button && <span>Submit</span>}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default RsvpForm;
