import '../App.css';
import React, {Component} from 'react';
import { Button, Spinner } from 'react-bootstrap';

class NoResultsForm extends Component {

  state = {
    disable_button: false
  }

  back = (event) =>  {
    event.preventDefault();
    this.props.setStep('search_form');
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>RSVP</h1>
        </header>
        <div className="App-body">
          <div className='form-center-text'>
            <p>
              We were unable to find your RSVP. Please reach out to Caitlyn or Sean if you're having trouble.
            </p>
          </div>
          <div className='pepper-no-result'>
            <img src="/Pepper-sad.png" alt="Pepper being sad."/>
          </div>
          <div className="footer-controls">
            <div gclassName="prev-button">
              <button type="button" class="btn btn-light" type="submit" onClick={this.back} disabled={this.state.disable_button}>
                {this.state.disable_button && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                )}
                {!this.state.disable_button && <span>Back</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NoResultsForm;
