import '../App.css';
import React, {Component} from 'react';


class RsvpClosedForm extends Component {

  state = {
    disable_button: false
  }


  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>RSVP</h1>
          <h3>We are no longer taking RSVPs.</h3>
        </header>
        <div className="App-body">
          <div className='form-center-text'>
            <p>
              <a href="https://www.caitlynandsean.com/">Click here to return to our wedding website.</a>
              <div className='pepper-no-result'>
                <img src="/Pepper-sad.png" alt="Pepper being sad."/>
              </div>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default RsvpClosedForm;
