import '../App.css';
import React, { Component } from 'react';

export class Success extends Component {
  continue = e => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
        </header>
        <div className="App-body">
          <div className="success-text">
            <h1>Thank you for your response!</h1>
            <p><a href="https://www.caitlynandsean.com/">Click here to return to our wedding website.</a></p>
          </div>
          <img src="/pepper1.png" alt="Pepper"/>
        </div>
      </div>
    );
  }
}

export default Success;
