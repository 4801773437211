import React, { Component } from 'react';
import SearchForm from './SearchForm';
import RsvpForm from './RsvpForm';
import MultipleResultsForm from './MultipleResultsForm';
import NoResultsForm from './NoResultsForm';
import RsvpClosedForm from './RsvpClosedForm';
import Success from './Success';


export class MainForm extends Component {
  state = {
    step: 'rsvp_closed_form',
    firstName: "",
    lastName: "",
    searchResults: [],
    multipleSearchResults: {},
  };

  setStep = (targetStep) => {
    this.setState({
      step: targetStep
    });
  };

  setFirstName = (first_name) => {
    this.setState({
      firstName: first_name
    });
  };

  setLastName = (last_name) => {
    this.setState({
      lastName: last_name
    });
  };

  setsearchResults = (search_results) => {
    this.setState({
      searchResults: search_results
    });
  };

  setmultipleSearchResults = (multiple_search_results) => {
    this.setState({
      multipleSearchResults: multiple_search_results
    });
  };

  updateSearchResults = (event, index) => {
    const field_name = event.target.id;
    const field_value = event.target.value == 'true';
    this.state.searchResults[index][field_name] = field_value
  };

  updateNameSearchResults = (event, index) => {
    const field_name = event.target.id;
    const field_value = event.target.value;
    this.state.searchResults[index][field_name] = field_value
  };

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { step } = this.state;
    
    switch (step) {
      case 'search_form':
        return (
          <SearchForm
            setStep={this.setStep}
            setFirstName={this.setFirstName}
            setLastName={this.setLastName}
            setsearchResults={this.setsearchResults}
            setmultipleSearchResults={this.setmultipleSearchResults}
            handleChange={this.handleChange}
          />
        );
      case 'rsvp_form':
        return (
          <RsvpForm
            setStep={this.setStep}
            handleChange={this.handleChange}
            searchResults={this.state.searchResults}
            updateBooleanSearchResults={this.updateBooleanSearchResults}
            updateNameSearchResults={this.updateNameSearchResults}
          />
        );
      case 'multiple_results_form':
        return (
          <MultipleResultsForm
            setStep={this.setStep}
            setsearchResults={this.setsearchResults}
            handleChange={this.handleChange}
            searchResults={this.state.searchResults}
            multipleSearchResults={this.state.multipleSearchResults}
          />
        );
      case 'no_results_form':
        return (
          <NoResultsForm
            setStep={this.setStep}
          />
        );
      case 'success_form':
        return <Success />;
      case 'rsvp_closed_form':
        return (
          <RsvpClosedForm />
        );
      default:
        (console.log('This is a multi-step form built with React.'))
    }
  }
}

export default MainForm;
