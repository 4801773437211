import '../App.css';
import axios from 'axios';
import React, {Component} from 'react';
import { Spinner } from 'react-bootstrap';

const getRSVPURL = "https://o3afgjrrg8.execute-api.us-east-1.amazonaws.com/Prod/get-rsvp/"
const likeGetRSVPURL = "https://o3afgjrrg8.execute-api.us-east-1.amazonaws.com/Prod/like-get-rsvp"

class SearchForm extends Component {

  state = {
    disable_button: false
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ disable_button: true });
    const first_name = this.state.firstName.trim()
    const last_name = this.state.lastName.trim()

    this.props.setFirstName(first_name);
    this.props.setLastName(last_name);

    axios.get(getRSVPURL, {
      params: {
        first_name: first_name,
        last_name: last_name,
      }
    })
    .then(get_response => {
      const search_results = get_response.data.result.Items
      if (search_results == null){
        console.info("No results where found. Trying like search.");
        axios.get(likeGetRSVPURL, {
          params: {
            first_name: first_name,
            last_name: last_name,
          }
        })
        .then(like_get_response => {
          if (like_get_response.data.statusCode == 404) {
            this.props.setStep('no_results_form');
          }
          else {
            const multiple_search_results = like_get_response.data.result
            this.props.setmultipleSearchResults(multiple_search_results);
            this.props.setStep('multiple_results_form');
          }
        })
        .catch(error => {
          this.setState({searchResults: error.message});
          console.error("There was an error.", error);
        })
      }
      else {
        this.props.setsearchResults(search_results);
        this.props.setStep('rsvp_form');
      }
    })
    .catch(error => {
      this.setState({searchResults: error.message});
      console.error("There was an error.", error);
      this.setState({ disable_button: false });
    })
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>RSVP</h1>
          <h2>Caitlyn & Sean</h2>
          <h3>08<span>&#183;</span>18<span>&#183;</span>2023</h3>
        </header>
        <div className="App-body">
          <div className='form-center-text'>
            Please enter your first and last name to search for your invite
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="wrapperhandleSubmit">
              <div className="input-form">
                <input type="text" name="firstName" placeholder="First Name" class="rounded" required value={this.props.firstName} onChange={this.handleChange}/>
              </div>
              <div className="input-form">
                <input type="text" name="lastName" placeholder="Last Name" class="rounded" required value={this.props.lastName} onChange={this.handleChange}/>
              </div>
              <div className="footer-controls">
                <div className="next-button">
                  <button type="button" class="btn btn-light" type="submit" disabled={this.state.disable_button}>
                    {this.state.disable_button && (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    )}
                    {!this.state.disable_button && <span>Search</span>}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default SearchForm;
